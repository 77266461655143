import validate from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/middleware/auth.global.ts";
import registration_45completion_45global from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/middleware/registrationCompletion.global.ts";
import manifest_45route_45rule from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  registration_45completion_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "validate-chapter-id": () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/middleware/validateChapterId.ts"),
  "validate-chapter-index": () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/middleware/validateChapterIndex.ts"),
  "validate-course-id": () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/middleware/validateCourseId.ts")
}