import { default as aboutZIqzWSxvgAMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/about.vue?macro=true";
import { default as _91chapterId_93YFaoAg5JiSMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/admin-dashboard/courses/[courseId]/chapters/[chapterId].vue?macro=true";
import { default as indexObdWJm2AG3Meta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/admin-dashboard/courses/[courseId]/index.vue?macro=true";
import { default as _91courseId_93QUZZ47kio4Meta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/admin-dashboard/courses/[courseId].vue?macro=true";
import { default as createNaChLndDsNMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/admin-dashboard/courses/create.vue?macro=true";
import { default as indexaitEWM0RJjMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/admin-dashboard/courses/index.vue?macro=true";
import { default as coursesIP6HIVxYoBMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/admin-dashboard/courses.vue?macro=true";
import { default as overviewGeZ8pybRfbMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/admin-dashboard/overview.vue?macro=true";
import { default as settings2K1soyPVYtMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/admin-dashboard/settings.vue?macro=true";
import { default as usersHSUyeb0Oh7Meta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/admin-dashboard/users.vue?macro=true";
import { default as admin_45dashboardMOvmcHJoGgMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/admin-dashboard.vue?macro=true";
import { default as complete_45registrationok5QRB3apNMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/complete-registration.vue?macro=true";
import { default as cookies_45policy9FLwTTIBTRMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/cookies-policy.vue?macro=true";
import { default as indext9607RWZ6lMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/courses/[courseId]/chapters/[chapterIndex]/index.vue?macro=true";
import { default as _91chapterIndex_93e5iTFoC91UMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/courses/[courseId]/chapters/[chapterIndex].vue?macro=true";
import { default as indexgpKDgluMc1Meta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/courses/[courseId]/index.vue?macro=true";
import { default as _91courseId_93RzAuTyKvzoMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/courses/[courseId].vue?macro=true";
import { default as indexRyWkvOi426Meta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/courses/index.vue?macro=true";
import { default as coursesIz0GW22e3OMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/courses.vue?macro=true";
import { default as email_45verificationjQEaTJ1UYyMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/email-verification.vue?macro=true";
import { default as eventsjQ5wBXJ5i7Meta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/events.vue?macro=true";
import { default as faqMrufNpTsHHMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/faq.vue?macro=true";
import { default as forgot_45password3PWiRFA7dtMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/forgot-password.vue?macro=true";
import { default as helpXw8UQacI2cMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/help.vue?macro=true";
import { default as indexO9aa4EtseeMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/index.vue?macro=true";
import { default as loginwKwk9ZFUmpMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/login.vue?macro=true";
import { default as materialsOl6Spn1V2wMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/materials.vue?macro=true";
import { default as password_45resetG7tzmY6optMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/password-reset.vue?macro=true";
import { default as privacy_45policyVhiWh9F508Meta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/privacy-policy.vue?macro=true";
import { default as emailS9Zz6J9qarMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/register/email.vue?macro=true";
import { default as index45tO4VYyLQMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/register/index.vue?macro=true";
import { default as registerofyR408bCdMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/register.vue?macro=true";
import { default as rodoaiC82jCukrMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/rodo.vue?macro=true";
import { default as subcription_45successfull6p1kOFhLB7Meta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/subcription-successfull.vue?macro=true";
import { default as subscribei0K8V9OhRlMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/subscribe.vue?macro=true";
import { default as subscription_45cancelledwWbltKXrifMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/subscription-cancelled.vue?macro=true";
import { default as terms_45of_45servicetG4qRpfuHuMeta } from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/terms-of-service.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "admin-dashboard",
    path: "/admin-dashboard",
    meta: admin_45dashboardMOvmcHJoGgMeta || {},
    redirect: admin_45dashboardMOvmcHJoGgMeta?.redirect,
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/admin-dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: coursesIP6HIVxYoBMeta?.name,
    path: "courses",
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/admin-dashboard/courses.vue").then(m => m.default || m),
    children: [
  {
    name: _91courseId_93QUZZ47kio4Meta?.name,
    path: ":courseId()",
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/admin-dashboard/courses/[courseId].vue").then(m => m.default || m),
    children: [
  {
    name: "admin-dashboard-courses-courseId-chapters-chapterId",
    path: "chapters/:chapterId()",
    meta: _91chapterId_93YFaoAg5JiSMeta || {},
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/admin-dashboard/courses/[courseId]/chapters/[chapterId].vue").then(m => m.default || m)
  },
  {
    name: "admin-dashboard-courses-courseId",
    path: "",
    meta: indexObdWJm2AG3Meta || {},
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/admin-dashboard/courses/[courseId]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "admin-dashboard-courses-create",
    path: "create",
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/admin-dashboard/courses/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-dashboard-courses",
    path: "",
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/admin-dashboard/courses/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "admin-dashboard-overview",
    path: "overview",
    meta: overviewGeZ8pybRfbMeta || {},
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/admin-dashboard/overview.vue").then(m => m.default || m)
  },
  {
    name: "admin-dashboard-settings",
    path: "settings",
    meta: settings2K1soyPVYtMeta || {},
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/admin-dashboard/settings.vue").then(m => m.default || m)
  },
  {
    name: "admin-dashboard-users",
    path: "users",
    meta: usersHSUyeb0Oh7Meta || {},
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/admin-dashboard/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "complete-registration",
    path: "/complete-registration",
    meta: complete_45registrationok5QRB3apNMeta || {},
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/complete-registration.vue").then(m => m.default || m)
  },
  {
    name: "cookies-policy",
    path: "/cookies-policy",
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/cookies-policy.vue").then(m => m.default || m)
  },
  {
    name: coursesIz0GW22e3OMeta?.name,
    path: "/courses",
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/courses.vue").then(m => m.default || m),
    children: [
  {
    name: _91courseId_93RzAuTyKvzoMeta?.name,
    path: ":courseId()",
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/courses/[courseId].vue").then(m => m.default || m),
    children: [
  {
    name: _91chapterIndex_93e5iTFoC91UMeta?.name,
    path: "chapters/:chapterIndex()",
    meta: _91chapterIndex_93e5iTFoC91UMeta || {},
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/courses/[courseId]/chapters/[chapterIndex].vue").then(m => m.default || m),
    children: [
  {
    name: "courses-courseId-chapters-chapterIndex",
    path: "",
    meta: indext9607RWZ6lMeta || {},
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/courses/[courseId]/chapters/[chapterIndex]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "courses-courseId",
    path: "",
    meta: indexgpKDgluMc1Meta || {},
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/courses/[courseId]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "courses",
    path: "",
    meta: indexRyWkvOi426Meta || {},
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/courses/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "email-verification",
    path: "/email-verification",
    meta: email_45verificationjQEaTJ1UYyMeta || {},
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/email-verification.vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    meta: eventsjQ5wBXJ5i7Meta || {},
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/events.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password3PWiRFA7dtMeta || {},
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexO9aa4EtseeMeta || {},
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginwKwk9ZFUmpMeta || {},
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "materials",
    path: "/materials",
    meta: materialsOl6Spn1V2wMeta || {},
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/materials.vue").then(m => m.default || m)
  },
  {
    name: "password-reset",
    path: "/password-reset",
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registerofyR408bCdMeta?.name,
    path: "/register",
    meta: registerofyR408bCdMeta || {},
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/register.vue").then(m => m.default || m),
    children: [
  {
    name: "register-email",
    path: "email",
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/register/email.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "",
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/register/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "rodo",
    path: "/rodo",
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/rodo.vue").then(m => m.default || m)
  },
  {
    name: "subcription-successfull",
    path: "/subcription-successfull",
    redirect: "/courses",
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/subcription-successfull.vue").then(m => m.default || m)
  },
  {
    name: "subscribe",
    path: "/subscribe",
    meta: subscribei0K8V9OhRlMeta || {},
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/subscribe.vue").then(m => m.default || m)
  },
  {
    name: "subscription-cancelled",
    path: "/subscription-cancelled",
    redirect: "/subscribe",
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/subscription-cancelled.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service",
    path: "/terms-of-service",
    component: () => import("/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/pages/terms-of-service.vue").then(m => m.default || m)
  }
]