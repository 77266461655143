import revive_payload_client_4sVQNw7RlN from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_izaWKZ8rEu from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_w2VlvAEcpa from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_8BKffvaEi5 from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import templates_plugin_229c1a27_TWx6aHp7Ai from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/.nuxt/templates.plugin.229c1a27.mts";
import plugin_AUP22rrBAc from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import api_GFfDXud5Cr from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/plugins/api.ts";
import errorHandler_x1Y4n5NP4w from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/plugins/errorHandler.ts";
import flagsmith_FvLfn3q8DA from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/plugins/flagsmith.ts";
import posthog_client_fUucxKWhZE from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/plugins/posthog.client.ts";
import toast_ysMjUcU7eJ from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/plugins/toast.ts";
import yup_OM8D6DMeq7 from "/home/runner/work/szkolenia-zacznij-od-wody-web/szkolenia-zacznij-od-wody-web/plugins/yup.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  plugin_w2VlvAEcpa,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  templates_plugin_229c1a27_TWx6aHp7Ai,
  plugin_AUP22rrBAc,
  api_GFfDXud5Cr,
  errorHandler_x1Y4n5NP4w,
  flagsmith_FvLfn3q8DA,
  posthog_client_fUucxKWhZE,
  toast_ysMjUcU7eJ,
  yup_OM8D6DMeq7
]