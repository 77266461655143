import { defineNuxtPlugin } from '#app'
import { createVuetify } from 'vuetify'

const isDev = process.env.NODE_ENV === "development"
const options = JSON.parse('{"ssr":true,"treeshaking":true,"useVuetifyLabs":true,"defaults":{"global":{"ripple":true},"VTextField":{"variant":"outlined"}},"theme":{"defaultTheme":"customDark","themes":{"customDark":{"dark":true,"colors":{"background":"#050505","surface":"#0C0C0C","primary":"#2ff2fd","secondary":"#0a2929","error":"#ff4c51","info":"#16b1ff","success":"#56ca00","warning":"#ffb400"}},"customLight":{"dark":false,"colors":{"background":"#fafafa","surface":"#F2F2F2","primary":"#2ff2fd","secondary":"#d6f5f5","error":"#ff4c51","info":"#16b1ff","success":"#56ca00","warning":"#ffb400"}}}}}')

''

export default defineNuxtPlugin(nuxtApp => {
  const vuetify = createVuetify(options)
  nuxtApp.vueApp.use(vuetify)

  if (!process.server && isDev) {
    // eslint-disable-next-line no-console
    console.log('💚 Initialized Vuetify 3', vuetify)
  }

  return {
    provide: {
      vuetify
    }
  }
})
